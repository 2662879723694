export const columns = [
  {
    title: '楼盘名称',
    dataIndex: 'name'
  },
  {
    title: '单价',
    dataIndex: 'salePrice',
    scopedSlots: { customRender: 'salePrice' }
  },
  {
    title: '开发商',
    dataIndex: 'developer',
    scopedSlots: { customRender: 'developer' }
  },
  {
    title: '所在位置',
    dataIndex: 'area',
    ellipsis: true
  },
  {
    title: '标签',
    dataIndex: 'label',
    scopedSlots: { customRender: 'label' }
  },
  {
    title: '人脸识别系统url',
    dataIndex: 'faceUrl',
    scopedSlots: { customRender: 'faceUrl' }
  },
  {
    title: '创建时间',
    dataIndex: 'createTime',
    sorter: true
  },
  {
    title: '操作',
    key: 'action',
    fixed: 'right',
    scopedSlots: { customRender: 'action' }
  }
]
