<template>
  <a-modal
    :title="title"
    :width="modalWidth"
    :visible="visible"
    :confirmLoading="loading"
    :maskClosable="false"
    @ok="save"
    @cancel="close"
  >
    <template slot="footer">
      <a-button key="back" @click="close">
        取消
      </a-button>
      <a-button key="submit" :loading="loading" type="primary" @click="save">
        确定
      </a-button>
    </template>
    <a-spin :spinning="loading">
      <a-form :form="form" v-bind="formLayout">
        <a-form-item label="楼盘名称" has-feedback>
          <a-input
            :autoFocus="true"
            allow-clear
            v-decorator="['name', { rules: [{ required: true, message: '请输入楼盘名称' }] }]"
            placeholder="请输入楼盘名称"
          />
        </a-form-item>
        <template v-if="btnPermission('residence_face')">
          <a-form-item label="人脸识别系统url" has-feedback>
            <a-input allow-clear :addon-before="faceBefore" placeholder="请输入数字" v-decorator="['faceUrl', { rules: [{ validator: intNumberValidator }] }]" />
          </a-form-item>
          <a-form-item label="报备时间规则(h)">
            <a-input
              allow-clear
              addon-before="到访前"
              addon-after="小时报备"
              v-decorator="['faceInterval', { rules: [{ validator: floatValidator }] }]"
              placeholder="请输入最多带两位小数的数字"
            />
          </a-form-item>
          <a-form-item label="人脸识别系统用户名" has-feedback>
            <a-input
              allow-clear
              v-decorator="['faceName', { rules: [{ validator: userValidator }] }]"
              placeholder="请输入英文和数字组成的5到12个字符"
            />
          </a-form-item>
          <a-form-item label="人脸识别系统密码" has-feedback>
            <a-input-password
              allow-clear
              v-decorator="['facePassword', { rules: [{ validator: passwordValidator }] }]"
              placeholder="请输入英文和数字组成的5到12个字符"
            />
          </a-form-item>
        </template>
        <a-form-item label="客户手机号输入规则">
          <a-radio-group v-decorator="['phoneType', { rules: [{ required: true, message: '请选择客户手机号显示规则'}] }]">
            <a-radio :value="1">
              全号段
            </a-radio>
            <a-radio :value="0">
              可选前3后4
            </a-radio>
          </a-radio-group>
        </a-form-item>
        <a-form-item label="开发商" has-feedback>
          <a-select
            allow-clear
            label-in-value
            showSearch
            placeholder="请选择开发商"
            class="inputLong"
            option-label-prop="label"
            v-decorator="['developer', { rules: [{ required: true, message: '请选择开发商' }] }]"
            :not-found-content="developerFetching ? undefined : null"
            @change="developerChange"
          >
            <a-spin v-if="developerFetching" slot="notFoundContent" size="small" />
            <a-select-option v-for="item in developerList" :key="item.value" :value="item.value" :label="item.name">
              {{ item.text }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="所在地区" has-feedback>
          <a-cascader
            allow-clear
            placeholder="请选择地区"
            v-decorator="['area', { rules: [{ required: true, message: '请选择所在地区' }] }]"
            :fieldNames="cityField"
            :options="options"
          />
        </a-form-item>
        <a-form-item label="楼盘类型" has-feedback>
          <a-select
            placeholder="请选择楼盘类型"
            v-decorator="['type']"
          >
            <a-select-option :value="0">住宅</a-select-option>
            <a-select-option :value="1">商办</a-select-option>
          </a-select>
        </a-form-item>
        <!-- <a-form-item label="驻场人员">
          <a-select
            mode="multiple"
            label-in-value
            placeholder="请选择驻场人员"
            style="width: 100%"
            :filter-option="false"
            :not-found-content="residentFetching ? undefined : null"
            @focus="fetchUser('')"
            @search="fetchUser"
            @change="handleResidentChange"
            v-decorator="[
              'resident',
              { initialValue: residentValue, rules: [{ required: true, message: '请选择驻场人员' }] }
            ]"
          >
            <a-spin v-if="residentFetching" slot="notFoundContent" size="small" />
            <a-select-option v-for="d in residentData" :key="d.value">
              {{ d.text }}
            </a-select-option>
          </a-select>
        </a-form-item> -->
        <a-form-item label="合同时间" has-feedback>
          <a-range-picker
            class="inputLong"
            v-decorator="['time', { rules: [{ required: true, message: '请输入起止日期' }] }]"
          />
        </a-form-item>
      </a-form>
    </a-spin>
  </a-modal>
</template>

<script>
import { houseAdd, houseEdit, houseGet, putHouseResident } from '@/api/house'
import { findDevelopers } from '@/api/developers'
import { findResidents } from '@/api/residents'
import { passwordValidator, userValidator, floatValidator, intNumberValidator } from '@/utils/util'

export default {
  data() {
    return {
      title: '新建',
      noticeTitle: '楼盘管理',
      fields: ['name', 'phoneType', 'developer', 'area', 'type', 'time', 'faceUrl', 'faceName', 'facePassword', 'faceInterval'],

      open: false,
      faceBefore: '/face/',

      form: this.$form.createForm(this),
      id: null,
      model: null,
      visible: false,
      loading: false,
      data: [],
      value: undefined,
      developerFetching: false,
      developerList: [],
      developer: {
        link: ''
      },
      fetching: false,
      // resident
      residentData: [],
      residentValue: [],
      residentFetching: false
    }
  },
  created() {
    this.formValidator(this.fields)
    this.developerFetch()
  },
  computed: {
    options() {
      const city = this.defaultCityOptions
      if (this.$store.state.city.city) {
        return this.$store.state.city.city
      } else {
        return city
      }
    },
    userInfo() {
      return this.$store.state.user.info
    },
    serial() {
      return this.$store.state.env.serial
    }
  },
  methods: {
    passwordValidator,
    floatValidator, 
    intNumberValidator,
    userValidator,
    show(record = null) {
      this.model = null
      this.form.resetFields()
      this.visible = true
      this.id = null
      if (record) {
        this.title = '修改'
        this.id = record.id
        this.fetchData()
      } else {
        this.title = '新建'
      }
    },
    close() {
      this.visible = false
    },
    fetchData() {
      this.loading = true
      houseGet(this.id)
        .then(res => {
          if (res.id) {
            const developer = res._embedded.developer
            this.model = res
            this.model.area = [res.province, res.city, res.district, res.street]
            this.model.time = [res.coopStartTime, res.coopEndTime]
            if (res.faceUrl) {
              const preIndex = res.faceUrl.length - 1
              const number = res.faceUrl.slice(6, preIndex)
              this.model.faceUrl = number === 'undefined' ? '' : number
            }
            this.model.developer = {
              key: `${developer.name},${developer.id}`,
              label: developer.name
            }
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
    save() {
      this.form.validateFields((err, values) => {
        console.log(values, 'values')
        if (!err) {
          const developer = this.serial + '/rest/developer/' + values.developer.key.split(',')[1]
          this.loading = true
          const params = {
            name: values.name,
            type: values.type,
            phoneType: values.phoneType,
            coopStartTime: this.dateFormat(values.time[0]._d || values.time[0]),
            coopEndTime: this.dateFormat(values.time[1]._d || values.time[1]),
            province: values.area[0],
            city: values.area[1],
            district: values.area[2],
            street: values.area[3],
            developer
          }
          const param = {
            faceInterval: values.faceInterval,
            faceName: values.faceName,
            facePassword: values.facePassword
          }
          if (values.faceUrl === '' || values.faceUrl === undefined) {
            param.faceUrl = ''
          } else {
            param.faceUrl = '/face/' + values.faceUrl + '/'
          }
          Object.assign(params, param)
          if (this.id) {
            this.edit(params)
          } else {
            this.add(params)
          }
        }
      })
    },
    add(data) {
      houseAdd(data)
        .then(res => {
          if (res.id) {
            this.$emit('reload')
            this.close()
            this.notiSuccess(this.noticeTitle, '新建成功')
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
    edit(data) {
      houseEdit(this.id, data).then(res => {
        this.$emit('reload')
        this.close()
        this.notiSuccess(this.noticeTitle, '编辑成功')
      }).finally(() => {
        this.loading = false
      })
    },
    developerFetch() {
      const developerParam = { keyword: '', ...this.maxPagination }
      this.developerFetching = true
      findDevelopers(developerParam)
        .then(res => {
          if (res._embedded) {
            this.developerList = res._embedded.developers.map(item => {
              return {
                name: item.name,
                text: `${item.name}(企业代码：${item.id})`,
                value: `${item.name},${item.id}`
              }
            })
          }
        })
        .finally(() => {
          this.developerFetching = false
        })
    },
    developerChange(value) {
      this.form.setFieldsValue({
        developer: value
      })
    },
    // resident
    bindResident(id) {
      const { residentValue, serial } = this
      const data = residentValue
        .map(item => {
          return serial + '/rest/residents/' + item.key
        })
        .join('\n')
      putHouseResident(id, data)
        .then(res => {
          this.form.resetFields()
          this.loading = false
          this.$router.push({
            path: '/residence/list'
          })
          this.$notification.success({
            message: this.noticeTitle,
            description: '新建成功'
          })
        })
        .catch(() => {
          this.loading = false
        })
    },
    fetchUser(value) {
      this.lastFetchId += 1
      const fetchId = this.lastFetchId
      this.residentData = []
      this.residentFetching = true
      const params = {
        keyword: value,
        page: 0,
        size: 100,
        sort: 'createTime,desc'
      }
      findResidents(params).then(res => {
        console.log(res, 'findResidents')
        if (fetchId !== this.lastFetchId) {
          // for fetch callback order
          return
        }
        const residents = res._embedded.residents
        const data = residents.map(item => ({
          text: item.name,
          value: item.id
        }))
        this.residentData = data
        this.residentFetching = false
      })
    },
    handleResidentChange(value) {
      console.log(value, 'value')
      Object.assign(this, {
        residentValue: value,
        residentData: [],
        residentFetching: false
      })
    },
    switchChange(checked) {
      this.open = checked
    }
  }
}
</script>

<style lang="less" scoped>
// 使用 scss, less 时，可以用 /deep/ 进行样式穿透
#createTower /deep/ .ant-card-body {
  height: 71vh;
}
</style>
